.email-wellness-verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center; 
  }
  
  .email-wellness-center-content {
    background-color: #fff; 
    padding: 20px;
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  
  .email-wellness-loading, .email-wellness-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  