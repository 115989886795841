.filter-sidebar {
  height: 100%;
  width: 250px;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #333;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  color: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.5);
}

.filter-sidebar.open {
  width: 250px;
}

.filter-sidebar.closed {
  width: 0;
}

.filter-sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
  transition: 0.3s;
}

.filter-sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#mainContent {
  transition: margin-left 0.5s;
  padding: 16px;
  flex-grow: 1; 
}

.main-content.open {
  margin-left: 0;
}

.main-content.closed {
  margin-left: 0;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  border: none;
  padding: 10px 15px;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 2;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}

.filter-sidebar a:hover {
  background-color: #575757;
}

.table-container {
  margin-top: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #333;
  color: white;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.pagination {
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  margin: 5px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #777;
  cursor: not-allowed;
}

.filter-container label {
  display: block;
  margin-bottom: 10px;
  color: white;
}

.filter-container input,
.filter-container select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}
