.profiles-table {
    width: 100%;
    border-collapse: collapse;
}

.profiles-table th, .profiles-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.profiles-table tbody tr:nth-child(even) {
    background-color: #dddddd; 
}
    
.profiles-table tbody tr:nth-child(odd) {
    background-color: #cccccc;
}
.profiles-table th {
    background-color: #f2f2f2;
}

.profile-pic {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
    width: 50px;
    height: 50;
}

.icon {
    vertical-align: middle;
}

.icon.green {
    color: green;
}

.icon.gray {
    color: gray;
}

.icon.red {
    color: red;
}

button:disabled {
    opacity: 0.5;
}

.bubble {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 15px;
    color: black;
    font-weight: bold;
}

.bubble.green {
    background-color: #d4edda; 
}

.bubble.yellow {
    background-color: #fff3cd;
}

.bubble.red {
    background-color: #f8d7da; 
}
