.view-threats .table th {
    width: auto;
    white-space: nowrap;
}

.view-threats .table th:nth-child(1) {
    width: 50px;
}

.view-threats .table th:nth-child(2) {
    width: 200px;
}

.view-threats .table th:nth-child(3) {
    width: 200px;
}

.view-threats .table th:nth-child(4) {
    width: 200px;
}

.view-threats .table th:nth-child(5) {
    width: 200px;
}

.view-threats .table th:nth-child(6) {
    width: 700px;
}

.view-threats .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
}
