.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .profile-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;
  }
  
  .form-group input[type="checkbox"] {
    width: auto;
    margin-top: 0;
  }
  
  /* button {
    padding: 0.75rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  } */
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  