.map-custom-marker {
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 14px;
    line-height: 14px;
}

.map-custom-marker.online {
    background-color: green;
}

.map-custom-marker.offline {
    background-color: gray;
}

.map-custom-marker.admin {
    border: 2px solid blue;
}

.map-custom-marker.user {
    border: 2px solid yellow;
}

.map-custom-marker.warning {
    border: 2px solid red;
}

.map-custom-marker.watch {
    border: 2px solid orange;
}
