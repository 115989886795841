.authorization-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.timer {
  margin-bottom: 20px;
}

.approvals-container {
  display: flex;
  gap: 20px;
}

.approver-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.approver-box.accept {
  background-color: green;
}

.approver-box.reject {
  background-color: red;
}

.approver-box.pending {
  background-color: gray;
}
