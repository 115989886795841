.files-container {
  padding: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.files-table {
  width: 100%;
  border-collapse: collapse;
}

.files-table th, .files-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.files-table th {
  background-color: #f4f4f4;
}

.files-table img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.files-table button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.files-table button:hover {
  background-color: #0056b3;
}
