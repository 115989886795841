.account-label {
    display: block;
    margin-bottom: 8px;
    color: #000000;
    width: 30%;
}
    
.account-input {
    width: 70%;
    padding: 10px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #777;
    border-radius: 4px;
    outline: none;
    background-color: #ffffff;
    color: #000000;
}