@import url("https://fonts.googleapis.com/css2?family=Dongle&family=Doppio+One&display=swap");
/* font add css  */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.card {
  border: none;
  background-color: unset;
}

.card .card-body {
  padding: 0;
}

section {
  display: block;
  overflow: hidden;
}

img {
  width: 100%;
}

body ul li {
  list-style: none;
}

body ul li a {
  text-decoration: none;
}

body section.row, body header.row, body footer.row {
  margin: 0;
}

body #success {
  display: none;
}

body #error {
  display: none;
}

body iframe {
  border: none;
}

body i:before {
  margin-left: 0px !important;
}

body a, body .btn, body button {
  outline: none;
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
  text-decoration: none;
}

body a:before, body a:after, body .btn:before, body .btn:after, body button:before, body button:after {
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}

body a:focus, body a:hover, body .btn:focus, body .btn:hover, body button:focus, body button:hover {
  outline: none;
  text-decoration: none;
  -webkit-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}
section {
  overflow: hidden;
}
body{
  padding-top: 57px;
}
/* header css start */
.header-section {
  padding: 10px 20px;
  background: #fff;
  padding-bottom: 0;
  border-bottom: 6px solid #D9D9D9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}

.header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 35px;
  align-items: center;
}

.header-logo {
  display: block;
}

.header-logo img {
  max-width: 200px;
}

.header-menu {
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-left: auto;
}


.header-menu ul{
  display: inline-flex;
  align-items: center;
}
.header-menu ul li {
  display: inline-block;
}
.header-section .theme-btn{
  height: 30px !important;
  padding: 0 25px !important;
}
.header-menu ul li a,
.header-menu ul li{
  display: inline-block;
  color: #000;
  font-family: Dongle;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 0px;
  padding-left: 20px;
  line-height: 1;
  display: inline-flex;
  gap: 8px;
}
.header-menu ul li img{
  max-width: 18px;
}
.header-menu ul li a.active {
  background: #D9D9D9;
}

.header-right-side {
  margin-left: auto;
}

.header-right-side li {
  display: inline-block;
  margin-left: 15px;
}

.header-right-side li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-family: Dongle;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
}

.header-right-side li a img {
  height: 25px;
  width: 25px;
}

/* header css end */
/* sidebar css start */
.sidebar-section {
  position: fixed;
  left: 0;
  background: #fff;
  width: 290px;
  height: 100vh;
  padding: 15px 10px;
  border-right: 6px solid #D9D9D9;
  transition: width 0.3s ease;
}

.sidebar-section.collapsed {
  width: 60px;
  padding: 15px 5px;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
}

.sidebar-wrapper li {
  display: block;
  margin-bottom: 5px;
}

.sidebar-wrapper li a,
.sidebar-wrapper li button {
  display: flex;
  align-items: center;
  padding: 6px 13px;
  gap: 10px;
  color: #000;
  font-family: Dongle;
  font-size: 24px;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
}

.sidebar-wrapper li a img {
  height: 24px;
  width: 24px;
}

.sidebar-wrapper li a.active {
  background: #D9D9D9;
}

.toggle-button {
  margin-bottom: 10px;
  cursor: pointer;
}

/* sidebar css end */
/* main css start */
.main-content {
  margin-left: 290px;
  transition: margin-left 0.3s ease, width 0.3s ease;
  padding: 20px;
}

.main-content.collapsed {
  margin-left: 60px;
  width: calc(100% - 60px);
}

.main-section {
  padding-left: 300px;
  overflow: hidden;
  padding-right: 20px;
}
.home{
  margin: 0 !important;
  padding-left: 290px;
}

.section-wrapper {
  width: 100%;
}

.tab-area {
  background: #D9D9D9;
  border-bottom: 1px solid #000;
}

.tab-area li a {
  color: #000;
  font-family: "Doppio One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 15px;
}

.tab-area li a img {
  height: 12px;
  width: 12px;
}

.tab-area li a.active {
  background: #fff;
}

.custom-range {
  padding: 10px 15px;
  background: #D9D9D9;
  display: flex;
  align-items: center;
  gap: 30px;
}

.custom-range .export {
  display: flex;
  align-items: center;
  margin-left: auto;
  color: #000;
  font-family: Dongle;
  font-size: 24px;
  gap: 8px;
}

.custom-range input, 
.custom-range select {
  max-width: 180px;
  min-width: 180px;
  color: #6F6F6F;
  font-family: Dongle;
  font-size: 20px;
  border-radius: 3px;
  background-color: #FFF;
  box-shadow: none !important;
  outline: none !important;
}

.inner-title {
  color: #000;
  font-family: Dongle;
  font-size: 35px;
  font-weight: 400;
}

.custom-range p {
  color: #000;
  font-family: Dongle;
  font-size: 24px;
  font-weight: 400;
}

.tab-section-content {
  padding: 10px;
}

.input-wrapper {
  margin-left: 20px;
  position: relative;
}

.input-wrapper img {
  position: absolute;
  right: 8px;
  top: 50%;
  height: 15px;
  width: 15px;
  transform: translate(0, -50%);
}

.mobile-menu-opener {
  display: none;
}

/* main css end */
.note-editable {
  min-height: 200px;
}

/* login css start */
.new-login-section {
  position: relative;
  overflow: hidden;
  font-family: Dongle;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2;
  margin-top: -50px;
  padding-top: 100px;
}

.new-login-section .login-bg img {
  width: 100%;
  object-fit: cover;
}

.new-login-section .login-form-section {
  background: #FFFFFF;
  box-shadow: 0px 14px 24px rgba(14, 31, 53, 0.07);
  border-radius: 16px;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
}


.login-form {
  padding: 20px;
}

.new-login-section .login-left-img img {
  width: 100%;
  height: 50%;
  position: center;
}

.new-login-form h2 {
  font-family: "Doppio One";
  font-size: 20px;
  line-height: 30px;
  color: #101828;
  margin: 0;
}

.new-login-form h2 span {
  color: #1570EF;
}

.new-login-form p {
  font-size: 22px;
  color: #667085;
  margin: 0;
  margin-bottom: 25px;
}

.new-login-form .form-control {
  background: #FFFFFF;
  padding: 5px 15px;
  height: 44px;
  border: 1px solid #D0D5DD;
  border-radius: 0px 4px 4px 0px;
  font-size: 20px;
}

.new-login-form .form-control:focus {
  border: 1px solid #1570EF;
}

.new-login-form .login-input-item {
  margin-bottom: 24px;
}

.new-login-form .login-input-item .input-group {
  flex-wrap: nowrap;
  margin: 0;
}

.new-login-form .login-input-item label {
  font-family: "Doppio One";
  font-size: 18px;
  line-height: 20px;
  color: #344054;
}

.new-login-form .login-input-item .left-img {
  height: 44px;
  width: 48px;
  min-width: 48px;
  line-height: 44px;
  background: #F4F5F7;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: #D0D5DD;
  border-radius: 4px 0px 0px 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-login-form .login-input-item .hide-pass,
.new-login-form .login-input-item .hideicon,
.new-login-form .login-input-item .hideicon1,
.new-login-form .login-input-item .showicon1,
.new-login-form .login-input-item .showicon2,
.new-login-form .login-input-item .hideicon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  cursor: pointer;
}

.new-login-form .input-wrp {
  position: relative;
  width: 100%;
}

.new-login-form .login-button-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 24px;
  background: rgb(217, 77, 21);
  height: 40px;
  line-height: 1;
  margin: 0;
  font-size: 15px;
}

.new-login-form .login-button-group button:nth-child(2) {
  background: rgb(255, 173, 57);
}

.new-login-form .login-button-group button:nth-child(3) {
  background: rgb(255, 85, 85);
}

.new-login-form .forget-pass {
  font-family: "Doppio One";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ca3535;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
}

.remember-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
}

.remember-check label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Doppio One";
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}

.new-login-form .forget-pass {
  margin: 0;
  color: var(--clr-primary);
}

.input-icon {
  width: 20px;
  height: 20px;
}

.new-login-form .login-input-item .input-group img {
  width: 16px;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group .left-img img {
  height: 100%;
  max-height: 100%;
  max-width: 40px;
  padding-right: 10px;
}

.input-group .form-control {
  flex-grow: 1;
}

.form-group {
  margin-bottom: 15px;
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  font-size: large;
}

.remember-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.forgot-pass {
  color: #ff0000;
  text-decoration: none;
}

.forgot-pass:hover {
  text-decoration: underline;
}

.btn-primary {
  background-color: #930000;
  border-color: #930000;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.bg-orange {
  background: #FF743E !important;
  color: #fff !important;
}

.new-login-form .forget-pass img {
  width: 16px;
}

.theme-btn {
  padding: 8px 25px;
  background: #930000 !important;
  font-size: 16px;
  font-family: "Doppio One";
  color: #fff !important;
}

.login-header img {
  max-width: 100px;
}

/* login css end */

.audits-table {
  margin-top: 40px;
}

.audits-table th {
  color: #000;
  font-size: 10px;
  font-family: "Doppio One";
  font-weight: 600;
  padding: 10px 25px;
  background: rgba(147, 0, 0, 0.1);
  text-align: center;
  width: 20%;
}

.audits-table td {
  padding: 10px 25px;
  font-size: 16px;
  font-family: Dongle;
  font-weight: 300;
  color: #000;
  text-align: center;
  border-bottom: 1px solid #eee;
  line-height: 15px;
}

.audits-table .table-button-group a,
.audits-table .table-button-group button {
  display: inline-block;
  padding: 2px 15px;
  border-radius: 8px;
  font-size: 14px;
  background: rgba(0, 128, 0, 0.2) !important;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: green !important;
  border: none;
  outline: none;
  box-shadow: none;
}

.tab-content h2 {
  margin-top: 30px;
}

.audits-table .table-button-group button:nth-child(2) {
  background: rgba(255, 0, 0, 0.2) !important;
  color: red !important;
}

.audits-table .table-button-group button:nth-child(3) {
  background: rgba(147, 0, 0, 0.2) !important;
  color: #930000 !important;
}

.audits-table .table-button-group {
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 276px;
  justify-content: flex-end;
}

.profile-label {
  font-family: "Doppio One";
  font-size: 14px;
  line-height: 24px;
  color: #667085;
}

.profile-input {
  background: #FFFFFF;
  padding: 5px 15px;
  height: 44px;
  border: 1px solid #D0D5DD;
  border-radius: 4px !important;
  font-size: 20px;
}

.input-group .left-img img {
  height: 20px;
  width: 20px !important;
  padding: 0;
}

.form-new label {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.form-new .form-control,
.form-new .form-select {
  padding: 10px 20px;
  font-size: 12px;
  color: #000;
}

.account-label {
  width: 100% !important;
}

.form-title {
  font-size: 18px;
}

.form-title-two {
  font-size: 14px;
  color: #000;
  text-decoration: underline;
}

.images-upload-wrapper {
  border: 2px dashed #eee;
  max-width: 150px;
  height: 150px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.uploaded-images h2 {
  font-size: 14px;
  margin-bottom: 10px;
}

.images-upload-wrapper input {
  display: none;
}

.images-upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  text-align: center;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 60px;
}

.images-upload-wrapper img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  position: absolute;
  z-index: 4;
}

.uploaded-images {}

.uploaded-images {}
/* responsive css start */
@media (max-width: 999px) {
  .new-login-form p {
    margin-bottom: 0;
  }

  .header-right-side {
    text-align: right;
  }

  .new-login-form {
    margin-top: 30px;
  }

  .header-logo img {
    max-width: 150px;
  }

  .header-menu ul li a {
    font-size: 18px;
    padding: 2px 6px;
  }

  .header-right-side li a {
    font-size: 18px;
  }

  .header-right-side li a img {
    height: 15px;
    width: 15px;
  }

  .header-logo {
    width: 100%;
    text-align: right;
  }

  .header-wrapper {
    flex-wrap: wrap;
    align-items: center;
    gap: 0;
    justify-content: space-between;
  }

  .header-menu {
    align-items: start;
    width: 70%;
  }

  .header-right-side {
    width: 44%;
    margin: 0;
    margin-top: 15px;
  }

  .header-section {
    padding: 10px 10px;
    padding-bottom: 0;
  }

  .mobile-menu-opener {
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    height: 30px;
    width: 30px;
  }

  .sidebar-section {
    left: -100%;
    transition: 0.3s;
    z-index: 99;
    border: none;
  }

  .sidebar-section.active {
    left: 0;
  }

  .main-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tab-area li a {
    font-size: 16px;
  }

  .custom-range p,
  .custom-range .export {
    font-size: 18px;
  }

  .custom-range input {
    min-width: 100px;
  }

  .header-menu ul li:nth-child(1),
  .header-menu ul li:nth-child(2) {
    display: none;
  }

  .header-menu {
    text-align: right;
    margin-top: 15px;
  }
}
/* responsive css end */
.app-pages {
  position: relative;
  background: #fff;
  overflow: hidden;
}

.box-item {
  padding: 30px;
  background: var(--yellow-gradient);
  box-shadow: 0px 10px 24px rgba(147, 0, 0, 0.3);
  border-radius: 12px;
  transition: 0.4s;
}

.box-item h3 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #fff;
}

.box-item p {
  font-size: 22px;
  color: #fff;
}

.box-one {
  background-image: linear-gradient(to right, #930000 0%, #93000066 51%, #930000 100%);
  background-size: 200% auto;
}

.box-two {
  background: linear-gradient(89.66deg, #11E4BB 0.29%, #5AFCDC 99.71%);
  background-size: 200% auto;
}

.box-three {
  background: linear-gradient(89.66deg, #4B9FFF 0.29%, #70B3FF 99.71%);
  background-size: 200% auto;
}

.box-four {
  background-image: linear-gradient(to right, #f9294c 0%, #FF7C92 51%, #f2294b 100%);
  background-size: 200% auto;
}

.welcome-title {
  font-size: 50px;
}

.box-item:hover {
  background-position: right center;
}
