.map-draggable-table {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
  
  .map-drag-handle {
    height: 10px;
    background-color: #ccc;
    cursor: ns-resize;
  }
  
  .map-table-content {
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .map-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .map-table th, .map-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .map-table th {
    background-color: #f4f4f4;
  }
  
  .map-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .map-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .map-draggable-table .status-dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px; 
  }
  
  .map-draggable-table .status-dot.online {
    background-color: green;
  }
  
  .map-draggable-table .status-dot.offline {
    background-color: gray;
  }
  
  
  .map-draggable-table .status-dot.watch {
    background-color: orange;
  }
  
  .map-draggable-table .status-dot.warning {
    background-color: red;
  }
  