.map-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 10;
}

.map-sidebar.open {
    transform: translateX(0);
}

.map-sidebar-content {
    padding: 20px;
}

.map-sidebar-content h2 {
    margin-top: 0;
}